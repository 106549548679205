import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ProcessSequence from '../components/processSequence';

import Layout from '../components/layout';
import SEO from '../components/seo';


const ExampleTemplate = ({ data, location }) => {
  const example = data.markdownRemark.frontmatter;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={example.title}
        description={example.details || data.markdownRemark.excerpt}
      />
      <article
        className={`post-content example-container ${example.source || 'no-image'}`}
      >
        <header className="post-content-header">
          <h1 className="post-content-title">{example.title}</h1>
        </header>

        <div className="row">
          <div className="col feature-container">
            <img src={example.anim.publicURL} className="example-output-gif" alt="output animation" />
          </div>
        </div>

        {/*

        {example.creditHandle && (
          <p className="post-content-excerpt">
            By
            {' '}
            <strong>
              <a href={example.creditLink}>
                {example.creditHandle}
              </a>
            </strong>
          </p>
        )}

        */}
        {/*
        {(example.grid || example.source || example.inputMessage) && (
          <React.Fragment>
            <br />
            <hr />
            <div className="recipe-container">
              <p className="post-content-excerpt">
                Ingredients
              </p>
              <ProcessSequence
                grid={example.grid}
                inputSrc={example.source}
                inputMessage={example.inputMessage}
                // outputSrc={example.anim.publicURL}
                showLabels
            />
            </div>
          </React.Fragment>
        )}
 */}
        <br />
        <br />
        <p className="post-content-excerpt">
          <strong>
            &#8593;
            <br />
            Output
            <br />
          </strong>
        </p>
        <div className="example-details-container">
          { example.source && (
            <div className="row">
              <div className="col">
                <Img fluid={example.source.childImageSharp.fluid} className="step-content" />
              </div>
            </div>
          ) }
          { example.inputMessage && (
            <div className="row">
              <div className="col">
                <div className="chat-container step-content">
                  <div className="yours messages">
                    <div className="message last">
                      {example.inputMessage}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          ) }

          {/*
          { example.anim && (
            <div className="row">
              <div className="col">
                <img src={example.anim.publicURL} className="step-content" alt="output animation" />
              </div>
            </div>
          ) }
          */}
          { example.grid && (
            <div className="row">
              <div className="col">
                <Link to={`/templates${example.grid.fields.slug}`}>
                  <Img fluid={example.grid.frontmatter.thumb.childImageSharp.fluid} className="step-content grid" />
                </Link>
                <br />
                <strong>{example.grid.frontmatter.title}</strong>
                {' '}
                &nbsp;
                <a href={example.grid.frontmatter.printFile.publicURL} className="button primary pill">
                  print
                </a>
              </div>
            </div>
          ) }
        </div>
      </article>
    </Layout>
  );
};

export default ExampleTemplate;

export const pageQuery = graphql`
  query ExampleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        details
        creditHandle
        creditLink
        inputMessage
        anim {
          id
          publicURL
          name
        }
        grid {
          fields {
            slug
          }
          frontmatter {
            title
            printFile {
              id
              publicURL
              name
            }
            thumb {
              childImageSharp {
                fluid(maxWidth: 420) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        source {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
